import { useParams } from 'react-router-dom'
import { DefaultCriteria } from '../defaultCriteria'
import { useResource } from './useResource'

export function ResourceDefaultCriteria() {
  const { id: resourceId } = useParams()
  const { data: { resource } = {} } = useResource({ id: resourceId || '' })

  return (
    <>
      <DefaultCriteria
        parentType={'Resource'}
        parentId={Number(resource?.id)}
        showScope={false}
        defaultCriteria={resource?.defaultCriteria?.reverse()}
      />
    </>
  )
}
