import { AdapterAdapterType, AdapterStatus } from '@forge/graphql/generated'

export const visibilityOptions: Record<AdapterStatus | '', string> = {
  '': 'All Statuses',
  [AdapterStatus.Live]: 'Live',
  [AdapterStatus.InProgress]: 'In Progress',
  [AdapterStatus.NotStarted]: 'Not Started',
  [AdapterStatus.Deprecated]: 'Deprecated'
}

export const adapterTypeOptions: Record<AdapterAdapterType | '', string> = {
  '': 'All Types',
  [AdapterAdapterType.Bridge]: 'Bridge Interactive Web API',
  [AdapterAdapterType.Flex]: 'Flex Web API',
  [AdapterAdapterType.Mlsgrid]: 'MLSGrid V1 Web API',
  [AdapterAdapterType.MlsgridV2]: 'MLSGrid V2 Web API',
  [AdapterAdapterType.Paragon]: 'Paragon Web API',
  [AdapterAdapterType.Rapattoni]: 'Rapattoni Web API',
  [AdapterAdapterType.RapattoniMedia]: 'Rapattoni Media',
  [AdapterAdapterType.Reso]: 'RESO',
  [AdapterAdapterType.ResoToken]: 'RESO Token',
  [AdapterAdapterType.ResoTransport]: 'RESO Transport',
  [AdapterAdapterType.Rets]: 'RETS',
  [AdapterAdapterType.RetsSubClass]: 'Rets Sub Class',
  [AdapterAdapterType.RetsReso]: 'RETS RESO',
  [AdapterAdapterType.Trestle]: 'Trestle'
}
